import React, { FC, useCallback } from 'react';

import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import SpecialistPreview from 'components/SpecialistPreview/SpecialistPreview';
import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";
import { useOrganization } from 'providers/OrganizationProvider';
import Content from 'components/Content/Content';

import { SpecialistDTO, SpecialistNearestTimeslotType, TTimeSlot } from '../../types';

import styles from './SpecialistsPage.module.scss';


const SpecialistsPage: FC = () => {
    const { navigateToOrganizationPage } = useOrganization();
    const { time, date, handleSpecialist, specialistsList, handleOrderData } = useStoreProviderContext();

    const handleClickTimeslot = useCallback((specialist: SpecialistDTO) => (date: SpecialistNearestTimeslotType['date'],timeslot: TTimeSlot) => {
        handleOrderData({
            specialist,
            time: timeslot,
            date: date ? new Date(date): null,
        })
    },[])

    const allSpecialistNotAvailable = specialistsList.every(specialist => !specialist.active);

    return (
        <div className={styles.component}>
            <OrderPageHeader defaultBackButton backButton={{onClick: navigateToOrganizationPage }}/>
            <Content>
                <div className={styles.content}>
                    {/*{!allSpecialistNotAvailable && (*/}
                    {/*  <div className={styles.anyOption} onClick={() => handleSpecialist(null)}>*/}
                    {/*    <span>*/}
                    {/*        {i18nt('DOES_NOT_MATTER_SPECIALIST')}*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {specialistsList.map(specialist => (
                        <SpecialistPreview
                            specialist={specialist}
                            onClick={() => handleSpecialist(specialist)}
                            onClickTimeSlot={handleClickTimeslot(specialist)}
                            key={`specialist-item-${specialist.id}`}
                            showNearestTimeslots={!(time && date)}
                            showAboutButton={true}
                        />
                    ))}
                </div>
            </Content>

        </div>
    )
}

export default SpecialistsPage;
