import React, { useCallback, useState} from 'react';

import ServiceListCategory from "components/ServiceListCategory/ServiceListCategory";
import {ServiceItemDTO, ServiceSubItemDTO} from "../types";

import styles from './ServicesList.module.scss';
import ServiceListItem from "../ServiceListItem/ServiceListItem";
import NoData from "../NoData/NoData";
import { i18nt } from 'translations/i18n';
import { FCC } from 'types';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';

export interface IServicesList {
    items: (ServiceItemDTO | ServiceSubItemDTO)[];
    handleChangeItem: (item: ServiceSubItemDTO, value: boolean) => void;
    searchValue: string;
    selectedServices: ServiceSubItemDTO[];
    isPreselectedQuery?: boolean;
    isLoading?: boolean;
}

const ServicesList: FCC<IServicesList> = ({items, handleChangeItem, searchValue, selectedServices = '', isPreselectedQuery, isLoading}) => {
    const [openedStates, setOpenedStates] = useState<number[]>([]);
    const filteredServicesList = items.filter(service => {
        //@ts-ignore
        return service.name.toLowerCase().includes(searchValue) || service?.items.some(serviceItem => serviceItem.name.toLowerCase().includes(searchValue));
    })

    const handleOpenState = useCallback((serviceId: number) => (value: boolean) => {
        if(value) {
            setOpenedStates(prevState => [...prevState, serviceId ])
        } else {
            setOpenedStates(prevState => prevState.filter(openedServiceId => openedServiceId !== serviceId))
        }
    },[])

    if(isLoading) {
        return (
            <div className={styles.component}>
                <SpinnerPage/>
            </div>
        )
    }

    return (
        <div className={styles.component}>
            {filteredServicesList.map(service => {

                // @ts-ignore
                if(service.type === 'item') {
                    return (
                        <ServiceListItem
                          item={service}
                          onChange={handleChangeItem}
                          //@ts-ignore
                          selected={!!selectedServices.find((selectedService: ServiceSubItemDTO) => selectedService.id === service.id)}
                          key={`service-list-item-${service.id}`}
                        />
                    )
                }


                return (
                    <ServiceListCategory
                        {...service}
                        onChange={handleChangeItem}
                        handleOpenState={handleOpenState(service.id)}
                        openState={!!searchValue || openedStates.includes(service.id)}
                        key={`service-list-item-${service.id}`}
                        searchValue={searchValue}
                        //@ts-ignore
                        selectedServices={selectedServices}
                        isPreselectedQuery={isPreselectedQuery}
                    />
                )

            })}
            {filteredServicesList.length === 0  && searchValue.length > 0 && (
                <NoData title={i18nt('NOTHING_WAS_FOUND_FOR_YOUR_SEARCH_QUERY')}/>
            )}

            {filteredServicesList.length === 0  && searchValue.length === 0 && (
                <NoData title={i18nt('NO_SERVICES')}/>
            )}

        </div>
    )
}

export default ServicesList;
