import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {FCC} from 'types';
import StoreProviderContext from './StoreProviderContext';
import {getAvailableTimeslots} from 'utils/getTimeslots';
import {
  ContactsDataType,
  OrderData,
  OrderDataExtended,
  ServicesDTO,
  ServiceSubItemDTO,
  SpecialistDTO,
  TTimeSlot,
} from 'components/types';
import {getEmployeesApi} from 'api/employees';
import {getServicesListApi, getServicesMaxDurationApi} from 'api/services';
import {format} from 'date-fns';
import {apiSubmitOrder} from 'api/order';
import {useOrganization} from 'providers/OrganizationProvider';
import _ from 'lodash';
import useLoading from 'hooks/useLoading';
import {isSingleService} from 'utils/storeUtils';
import showNotification from 'components/Notifications/showNotification';
import {i18nt} from 'translations/i18n';
import {useAuth} from 'providers/AuthProvider';
import {
  extendItemsByMaxDuration,
  extendItemsByNotAvailableByOneEmployee,
} from 'providers/StoreProvider/utils';

export type OrderDataType = {
  date: Date | null,
  time: TTimeSlot | null,
  specialist?: SpecialistDTO | null,
  services: ServiceSubItemDTO[],
}

export type OrderDataOptionalType = {
  date?: Date | null,
  time?: TTimeSlot | null,
  specialist?: SpecialistDTO | null,
  services?: ServiceSubItemDTO[],
}

export const INITIAL_ORDER_DATA = {
  date: null,
  time: null,
  specialist: undefined,
  services: [],
  maxDuration: 0,
}

const StoreProvider: FCC = ({
                              children,
                            }) => {
  const {organization, navigateToOrganizationPage} = useOrganization();
  const {user} = useAuth();

  const singleEmployee = organization?.singleEmployee;
  const singleService = organization?.singleService;
  const shortOptionsInitialData = (singleEmployee || singleService) ? {
    ...INITIAL_ORDER_DATA,
    specialist: singleEmployee || undefined,
    services: singleService ? [singleService] : [],
  } as OrderDataType : null;
  const orderInitialData = shortOptionsInitialData || INITIAL_ORDER_DATA

  // TODO: During realization of feedback page was decided to remove short options (but decided to left code for future)
  // const shortOptions = Boolean(shortOptionsInitialData);
  const shortOptions = false;

  const [orderData, setOrderData] = useState<OrderDataType>(orderInitialData)

  const {date, time, specialist, services} = orderData;

  // view data
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);

  const [specialistsList, setSpecialistsList] = useState<SpecialistDTO[]>([]);
  const [specialistsListIsLoading, setSpecialistsListIsLoading] = useLoading(true);

  const [servicesList, setServicesList] = useState<ServicesDTO>([])
  const [servicesListIsLoading, setServicesListIsLoading] = useLoading(true);

  const [successOrder, setSuccessOrder] = useState<OrderDataExtended>();

  const [maxDuration, setMaxDuration] = useState<number>(0);
  const [isLoadingMaxDuration, setMaxDurationIsLoading] = useLoading(true);

  const transitionToRoute = (props?: any) => {

    // inner data
    const _time = props?.time || time;
    const _date = props?.date || date;
    const _services = props?.services || services;
    const _specialist = props?.specialist || specialist;

    // helpers
    const dateAndTime = _date && _time;
    const specialistSelected = _specialist !== undefined;
    const servicesSelected = _services.length > 0

    if (date && !_time) {
      navigateToOrganizationPage('time')
    } else if (dateAndTime && specialistSelected && servicesSelected) {
      navigateToOrganizationPage('contacts')
    } else if (dateAndTime && specialistSelected) {
      navigateToOrganizationPage('services')
    } else if (dateAndTime && _services.length > 0) {
      navigateToOrganizationPage('specialists')
    } else if (!dateAndTime && !specialistSelected && servicesSelected) {
      navigateToOrganizationPage('date')
    } else {
      navigateToOrganizationPage()
    }
  }

  useEffect(() => {
    getServicesLists();
  }, [organization, time, specialist, user])

  useEffect(() => {
    setAvailableTimeSlots(getAvailableTimeslots())
  }, [date, user])

  //specialists list
  useEffect(() => {
    getSpecialistsLists();
  }, [organization, time, services, user])


  const updateDateByDay = (date: Date | null) => {
    setOrderData({...orderData, date})
  }

  const setDate = (value: Date | null) => {
    const newData = {...orderData, date: value};
    setOrderData(newData);
  }
  const setTime = (value: TTimeSlot | null) => {
    const newData = {...orderData, time: value};
    setOrderData(newData);
  }

  const setSpecialist = (value: SpecialistDTO | null) => {
    const newData = {...orderData, specialist: value};
    setOrderData(newData);
  }

  const setServices = (value: ServiceSubItemDTO[]) => {
    const newData = {...orderData, services: value};
    setOrderData(newData);
  }


  // set data with transition
  const handleDate = (value: Date | null) => {
    const newData = {...orderData, date: value};
    setOrderData(newData);
    transitionToRoute(newData)
  }
  const handleTime = (value: TTimeSlot | null) => {
    const newData = {...orderData, time: value};
    setOrderData(newData);
    transitionToRoute(newData)
  }

  const handleSpecialist = (value: SpecialistDTO | null) => {
    const newData = {...orderData, specialist: value};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleService = (value: ServiceSubItemDTO[]) => {
    const newData = {...orderData, services: value};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleOrderData = (data: OrderDataOptionalType) => {
    const newData = {...orderData, ...data};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleOrderDataNoTransition = (data: OrderDataOptionalType) => {
    const newData = {...orderData, ...data};
    setOrderData(newData);
  }

  const getQueryForServices = () => {
    const query = [];
    if (date && time) query.push(`datetime=${format(new Date(date), 'yyyy-MM-dd')}T${time}:00`)
    if (specialist) query.push(`employee_id=${specialist.id}`);
    return query.join('&');
  }

  // data
  const getServicesLists = () => {
    if (!organization) return;
    setServicesListIsLoading(true);
    const query = getQueryForServices();

    getServicesListApi({organizationId: organization.id, queryString: query}).then(result => {
      setServicesList(result?.data);
      setServicesListIsLoading(false);
    })

  };

  const getSpecialistsLists = () => {
    if (!organization) return;
    setSpecialistsListIsLoading(true);
    const query: string[] = [];
    if (date && time) query.push(`datetime=${format(new Date(date), 'yyyy-MM-dd')}T${time}:00`);
    if (services.length > 0) query.push(`services_ids=${services.map(item => item.id).join(',')}`);

    getEmployeesApi({organizationId: organization.id, queryString: query.join('&')}).then(result => {
      setSpecialistsList(result?.data);
      setSpecialistsListIsLoading(false);
    })
  }

  const resetLoadedOptions = () => {
    // console.log('resetLoadedOptions');
    getSpecialistsLists();
    getServicesLists();
    setAvailableTimeSlots(getAvailableTimeslots())
  }

  const submitUserOrder = (data: ContactsDataType & { locale: string }) => {
    if (date && time && services && organization) {
      const employee_id = specialist === null ? null : (specialist?.id || null)
      const fullOrderData: OrderData & { locale: string } = {
        contactDetails: data,
        date: format(new Date(date), 'yyyy-MM-dd'),
        time,
        employee_id,
        services_ids: services.map(service => service.id),
        locale: data.locale,
      }

      return apiSubmitOrder(fullOrderData, organization.id)
        .then(response => {
        setSuccessOrder(response?.data);
        resetForm();
        navigateToOrganizationPage(`order/${response?.data?.hash}`);
        showNotification({
          type: 'success',
          content: i18nt('ORDER_CREATION_SUCCESS')
        });
      })
        .catch((error) => {

          showNotification({
            type: 'error',
            content: i18nt('ORDER_CREATION_ERROR')
          });
          throw error;
        })
    }

    showNotification({
      type: 'error',
      content: i18nt('ORDER_CREATION_ERROR')
    });

    return Promise.reject();

  };

  const resetForm = () => {
    setOrderData(orderInitialData);
  }

  const loadMaxDuration = useCallback(() => {
    const query = getQueryForServices();
    setMaxDurationIsLoading(true);
    getServicesMaxDurationApi({organizationId: organization?.id || 0, queryString: query})
      .then(result => {
        setMaxDuration(result?.data.maxDuration || 0);
      })
      .finally(() => {
        setMaxDurationIsLoading(false);
      });

  }, [specialistsList, date, time])

  //handle shortOptions
  useEffect(() => {
    if (specialistsList.length === 1 && specialist?.id !== specialistsList?.[0].id) {
      setSpecialist(specialistsList[0])
    }

    if (isSingleService(servicesList) && !_.isEqual(services, [servicesList[0]])) {
      setServices(servicesList as ServiceSubItemDTO[])
    }
  }, [specialistsList, servicesList])

  useEffect(() => {
    loadMaxDuration();
  }, [specialist, time, date]);



  const servicesListExtended = useMemo(() => {
    const extendedItemsByNotAvailableByOneEmployee = extendItemsByNotAvailableByOneEmployee({
      selectedServices: services,
      servicesList,
      specialistsList
    })
    /*
    * if service length more than available duration - {active} property value changed to {false}
    * */
    const servicesCheckedWithMaxDuration = extendItemsByMaxDuration({servicesList:extendedItemsByNotAvailableByOneEmployee, selectedServices:services, maxDuration});

    return servicesCheckedWithMaxDuration;
  }, [servicesList, specialistsList, services, maxDuration])

  // const { availableServicesDuration } = getSelectedServicesData(services, maxDuration);
  // console.log({
  //   servicesListExtended,
  //   maxDuration,
  //   availableServicesDuration,
  // })

  const value = useMemo(
    () => ({
      date,
      setDate,
      handleDate,

      time,
      setTime,
      handleTime,

      specialist,
      setSpecialist,
      handleSpecialist,

      servicesList: servicesListExtended,
      services,
      setServices,
      handleService,

      // data API
      specialistsList,
      availableTimeSlots,

      submitUserOrder,

      updateDateByDay,

      handleOrderData,
      handleOrderDataNoTransition,

      successOrder,
      setSuccessOrder,
      transitionToRoute,

      shortOptions,
      isLoading: specialistsListIsLoading || servicesListIsLoading,
      isLoadingMaxDuration,

      setOrderData: (data: OrderDataType) => setOrderData(data),
      resetOrderListData: resetForm,
      singleEmployee,
      singleService,

      resetLoadedOptions,
      maxDuration,
    }),
    [
      date,
      setDate,
      handleDate,

      time,
      setTime,
      handleTime,

      specialist,
      setSpecialist,

      servicesList,
      services,
      setServices,
      handleService,

      // data API
      availableTimeSlots,
      specialistsList,

      submitUserOrder,

      updateDateByDay,

      handleOrderData,
      handleOrderDataNoTransition,

      successOrder,
      setSuccessOrder,
      transitionToRoute,

      setOrderData,
      resetForm,

      resetLoadedOptions,

      shortOptions,
      singleEmployee,
      singleService,
      maxDuration,
      servicesListExtended,
      isLoadingMaxDuration,
    ],
  );

  return <StoreProviderContext.Provider value={value}>{children}</StoreProviderContext.Provider>;
};

export const useStoreProviderContext = () => useContext(StoreProviderContext);

export default StoreProvider;
