import React, {FC, useCallback, useState} from 'react';
import _ from 'lodash';

import {useOrganization} from 'providers/OrganizationProvider';
import OrderPageHeader from '../../OrderPageHeader/OrderPageHeader';
import Content from '../../Content/Content';
import InputSearch from '../../InputSearch/InputSearch';
import {useStoreProviderContext} from 'providers/StoreProvider/StoreProvider';
import {ServicesDTO, ServiceSubItemDTO} from '../../types';
import ServicesList from '../../ServicesList/ServicesList';

import styles from './ServicesPage.module.scss';

const ServicesPage: FC = () => {
  const {navigateToOrganizationPage} = useOrganization();
  const {servicesList, services, handleOrderDataNoTransition, time, specialist, isLoadingMaxDuration} = useStoreProviderContext();

  const isPreselectedQuery = Boolean(time || specialist);

  const [searchValue, setSearchValue] = useState('')

  const handleChangeItem = useCallback((item: ServiceSubItemDTO, value: boolean) => {
    const _services = services || [];
    if (value) {
      const uniqServices = _.uniqBy([..._services, item], 'id');
      handleOrderDataNoTransition({services: uniqServices})
    } else {
      handleOrderDataNoTransition({services: _services.filter(i => i.id !== item.id)})

    }
  }, [services]);

  const filteredServicesList = searchValue && servicesList ?
    servicesList.reduce((acc: ServicesDTO, service) => {
      const isInCategoryName = service.name.toLowerCase().includes(searchValue.toLowerCase());
      if (isInCategoryName) {
        return [...acc, service];
      }

      const isInCategoryItems = service.type === 'category' ? service.items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : [];
      if (isInCategoryItems.length) {
        return [...acc, {
          ...service,
          items: isInCategoryItems
        }]
      }
      return acc;

    }, []) : servicesList;

  return (
    <div className={styles.component}>
      <OrderPageHeader defaultBackButton backButton={{onClick: navigateToOrganizationPage}}/>

      <div className={styles.content}>

        {filteredServicesList?.length > 10 && (
          <div className={styles.inputBox}>
            <InputSearch
              className={styles.input}
              value={searchValue}
              onChange={(e, value) => setSearchValue(value)}
              onClear={() => setSearchValue('')}
            />
          </div>
        )}

        <Content className={styles.contentOverride}>
          <ServicesList
            items={filteredServicesList}
            handleChangeItem={handleChangeItem}
            searchValue={searchValue}
            selectedServices={services}
            isPreselectedQuery={isPreselectedQuery}
            isLoading={isLoadingMaxDuration}
          />
        </Content>
      </div>

    </div>
  )
}

export default ServicesPage;
