import {ServicesDTO, ServiceSubItemDTO, SpecialistDTO} from 'components/types';

// General methods
export const getSelectedServicesData = (services:  ServiceSubItemDTO[], maxDuration: number) => {
  let selectedServicesDuration = 0;
  let selectedServicesIds: number[] = [];

  services.forEach(service => {
    selectedServicesDuration += Number(service.durationMin || service.duration);
    selectedServicesIds.push(service.id);
  });

  return {
    selectedServicesDuration,
    selectedServicesIds,
    availableServicesDuration: maxDuration - selectedServicesDuration,
  }
};


// Specified methods

type ExtendItemsByMaxDurationType = {
  servicesList: ServicesDTO,
  selectedServices: ServiceSubItemDTO[],
  maxDuration: number
}

export const extendItemsByMaxDuration = ({servicesList, selectedServices, maxDuration}: ExtendItemsByMaxDurationType) => {
  const { selectedServicesIds, availableServicesDuration } = getSelectedServicesData(selectedServices, maxDuration);

  //Reusable functions
  const isSelectedServices = (service: ServiceSubItemDTO) => selectedServicesIds.includes(service.id);
  const isServiceDurationMoreThenAvailable = (service: ServiceSubItemDTO) => (service?.durationMin || service.duration) > availableServicesDuration;


  // main logic
  return servicesList.map(service => {
    if(service.type === 'item' && !isSelectedServices(service) && isServiceDurationMoreThenAvailable(service)) {
      return {
        ...service,
        active: false
      }
    }

    if(service.type === 'category') {
      // 1. just option to skip iteration
      if(service?.active === false) return service;

      // 2. if all child elements selected - we don't need to check duration
      const isAllChildElementsSelected = service.items.every(item => isSelectedServices(item));
      if(isAllChildElementsSelected) return service;


      // 3. if all child elements duration more then available duration - we need to disable this category and each child element
      const allChildMoreThenMaxDuration = service.items.every(item => isServiceDurationMoreThenAvailable(item));

      if(allChildMoreThenMaxDuration) {
        return {
          ...service,
          items: service.items.map(item => {
            return {
              ...item,
              active: false
            }
          }),
          active: false
        }
      }

      return {
        ...service,
        items: service.items.map(item => {
          if(!isSelectedServices(item) && isServiceDurationMoreThenAvailable(item)) {
            return {
              ...item,
              active: true
            }
          }

          return item;
        }),
        active: true
      }
    }

    return service;
  })

};



/**
 * Problem:
 * we have 5 services [service1, service2, service3, service4, service5]
 * and 2 employees [employee_1, employee_2]
 *
 * employee_1 can do [service1, service2, service3]
 * employee_2 can do [service3, service4, service5]
 *
 * if user select service3 - we show all services as available because this service can do any employee,
 * but when user select service4 - we need to show only employee_2 services
 * because we can't select two employees in one order
 * */
export type ServicesListWithPossibleOptionsByEmployeeType = {
  selectedServices: ServiceSubItemDTO[],
  servicesList: ServicesDTO,
  specialistsList: SpecialistDTO[]
}

// const extendItemsByNotAvailableByOneEmployee
export const extendItemsByNotAvailableByOneEmployee = ({selectedServices, servicesList, specialistsList }: ServicesListWithPossibleOptionsByEmployeeType) => {
  const selectedServicesIds = selectedServices.map(service => service.id);
  if(selectedServicesIds.length === 0) return servicesList;

  const specialistServicesArrays: number[][] = specialistsList.map(employee => employee.services.reduce((acc,service) => {
    if(service.type === 'item') {
      return [...acc, service.id];
    }

    if(service.type === 'category') {
      return [...acc, ...service.items.map(item => item.id)];
    }

    return acc;
  }, [] as number[]));

  let availableServicesItemsIds: number[] = [];
  specialistServicesArrays.forEach((servicesBySpecialist) => {
    const allServicesAvailable = selectedServicesIds.every(serviceId => servicesBySpecialist.includes(serviceId));

    if(allServicesAvailable) {
      availableServicesItemsIds = [...availableServicesItemsIds, ...servicesBySpecialist]
    }
  })

  return servicesList.map(service => {
    if(service.type === 'item') {
      return {
        ...service,
        notAvailableByOneEmployee: !availableServicesItemsIds.includes(service.id)
      }
    }

    return {
      ...service,
      notAvailableByOneEmployee: !service.items.every(item => availableServicesItemsIds.includes(item.id)),
      items: service.items.map(item => {
        return {
          ...item,
          notAvailableByOneEmployee: !availableServicesItemsIds.includes(item.id)
        }
      }),
    }
  });
};