import React, { useState } from 'react';

import { useOrganizations } from 'providers/OrganizationsProvider';
import InputSearch from 'components/InputSearch/InputSearch';
import LanguageButton from 'components/Language/LanguageButton/LanguageButton';
import OrganizationListItem from '../OrganizationListItem/OrganizationListItem';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';

import styles from './OrganizationsPage.module.scss'

const OrganizationsPage = ({}) => {
  const {organizations, isLoading } = useOrganizations();
  const [ searchInput, setSearchInput ] = useState('');

  const filteredOrganizations = organizations.filter(o => {
    return o.name.toLowerCase().includes(searchInput.toLowerCase()) || o?.address?.toLowerCase().includes(searchInput.toLowerCase())
  })

  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <div className={styles.inputBox}>
          <InputSearch
            onChange={(e, value) => setSearchInput(value)}
            value={searchInput}
            className={styles.search}
            onClear={() => setSearchInput('')}
          />

        </div>
        <LanguageButton className={styles.language}/>
      </div>
      <div className={styles.list}>
        {isLoading && (
          <SpinnerPage />
        )}
        {!isLoading && filteredOrganizations.length > 0 && (
          <>
            {filteredOrganizations.map(organization => (
              <OrganizationListItem {...organization} key={`organization-${organization.id}`}/>
            ))}
          </>
        )}

        {!filteredOrganizations.length && !isLoading && (
          <>No organization</>
        )}

      </div>
    </div>
  )
}

export default OrganizationsPage;
